import { graphql, Link, useStaticQuery } from "gatsby";
import React from "react";
import Container from "../container/container";
import * as styles from "./footer.module.scss";

function Footer() {
  const { prismicContactPage } = useStaticQuery(graphql`
    query FooterQuery {
      prismicContactPage {
        _previewable
        data {
          facebook_link
        }
      }
    }
  `);
  const data = prismicContactPage.data;

  return (
    <footer className={`${styles.footer} bg-dark`}>
      <Container>
        <nav className={styles.footerInner}>
          <a href={data.facebook_link} target="_blank" rel="noreferrer" className={styles.fb}>
            <svg
              height="48"
              width="48"
              fill="none"
              stroke="#fff"
              strokeLinecap="round"
              strokeWidth="1"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M18 2h-3a5 5 0 0 0-5 5v3H7v4h3v8h4v-8h3l1-4h-4V7a1 1 0 0 1 1-1h3z" />
            </svg>
            Facebook
          </a>

          <div className={styles.legal}>
            <Link to="/privacy/">Privacy</Link>
            <span>|</span>
            <span>De Gouden Wegwijzer</span>
          </div>
        </nav>
      </Container>
    </footer>
  );
}

export default Footer;

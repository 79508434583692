import PropTypes from "prop-types";

import React from "react";
import * as styles from "./container.module.scss";

function Container(props) {
  return <div className={`${styles.container} ${props.center ? styles.center : ""}`}>{props.children}</div>;
}

Container.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Container;

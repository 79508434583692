import React, { useContext, useEffect } from "react";
import Breadcrumb from "../breadcrumb/breadcrumb";
import Context from "../context";
import * as styles from "./breadcrumb-container.module.scss";

function BreadcrumbContainer({ path }) {
  const context = useContext(Context);
  const { breadcrumb } = context.data;

  useEffect(() => {
    context.set({ breadcrumb: [] });
  }, [path]);

  return (
    <div className={styles.container}>
      <div className={styles.breadcrumb}>
        <Breadcrumb items={breadcrumb}></Breadcrumb>
      </div>
    </div>
  );
}

export default BreadcrumbContainer;

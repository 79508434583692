// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-aanbod-jsx": () => import("./../../../src/pages/aanbod.jsx" /* webpackChunkName: "component---src-pages-aanbod-jsx" */),
  "component---src-pages-contact-jsx": () => import("./../../../src/pages/contact.jsx" /* webpackChunkName: "component---src-pages-contact-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-preview-jsx": () => import("./../../../src/pages/preview.jsx" /* webpackChunkName: "component---src-pages-preview-jsx" */),
  "component---src-templates-aanbod-category-jsx": () => import("./../../../src/templates/aanbod-category.jsx" /* webpackChunkName: "component---src-templates-aanbod-category-jsx" */),
  "component---src-templates-aanbod-item-jsx": () => import("./../../../src/templates/aanbod-item.jsx" /* webpackChunkName: "component---src-templates-aanbod-item-jsx" */),
  "component---src-templates-default-page-jsx": () => import("./../../../src/templates/defaultPage.jsx" /* webpackChunkName: "component---src-templates-default-page-jsx" */)
}


import React from "react";
import BreadcrumbContainer from "../breadcrumb-container/breadcrumb-container";
import { ContextProviderComponent } from "../context";
import Footer from "../footer/footer";
import Header from "../header/header";
import * as styles from "./layout.module.scss";

function Layout({ children, path, ...rest }) {
  if (rest["*"] === "offline-plugin-app-shell-fallback") {
    // gatsby-plugin-offline caches and uses an app-shell which should be empty.
    // gatsby-plugin-layout on the other hand forces every route to use the Layout component.
    // This causes a hydration issue.
    // E.g. when using activeClassName, the active state is not working after refresh when using the cached app-shell.
    // This fix will make sure the app-shell doesn't include the Layout component.
    // https://github.com/gatsbyjs/gatsby/issues/11738
    return null;
  }

  return (
    <div className={styles.body}>
      <ContextProviderComponent>
        <Header path={path} />
        <BreadcrumbContainer path={path}></BreadcrumbContainer>
        <main className={`${styles.main}`}>{children}</main>
        <Footer />
      </ContextProviderComponent>
    </div>
  );
}

export default Layout;

import React from "react";
import PropTypes from "prop-types";

function Image(props) {
  if (!props.source) {
    return null;
  }
  const width = props.width || props.source.dimensions?.width;
  const height = props.height || props.source.dimensions?.height;
  const alt = props.alt || props.source.alt;
  let srcSetXs = getSrcSet("xs");
  let srcSetSm = getSrcSet("sm");
  let srcSetMd = getSrcSet("md");
  let srcSetLg = getSrcSet("lg");
  let srcSetXl = getSrcSet("xl");
  let srcSetMax = getSrcSet("max");
  let url =
    props.source.url ||
    props.source.thumbnails?.max?.url ||
    props.source.thumbnails?.xl?.url ||
    props.source.thumbnails?.lg?.url ||
    props.source.thumbnails?.md?.url ||
    props.source.thumbnails?.sm?.url ||
    props.source.thumbnails?.xs?.url;
  url = ensureSpacesAreEncoded(url);

  function getSrcSet(size) {
    let srcSet;
    if (props.source.thumbnails && props.source.thumbnails[size]) {
      srcSet = ensureSpacesAreEncoded(props.source.thumbnails[size].url);
      if (props.source.thumbnails[size + "_2x"]) {
        srcSet += ` 1x, ${ensureSpacesAreEncoded(props.source.thumbnails[size + "_2x"].url)} 2x`;
      }
      if (props.source.thumbnails[size + "_3x"]) {
        srcSet += `, ${ensureSpacesAreEncoded(props.source.thumbnails[size + "_3x"].url)} 3x`;
      }
    }
    return srcSet;
  }

  // Apparently with the prismic preview feature, the spaces within the image urls are not encoded.
  function ensureSpacesAreEncoded(url) {
    if (url) {
      return url.replace(/ /g, "%20");
    }
  }

  return (
    <picture>
      {srcSetMax && <source media="(min-width: 1600px)" srcSet={srcSetMax} />}
      {srcSetXl && <source media="(min-width: 1280px)" srcSet={srcSetXl} />}
      {srcSetLg && <source media="(min-width: 1024px)" srcSet={srcSetLg} />}
      {srcSetMd && <source media="(min-width: 768px)" srcSet={srcSetMd} />}
      {srcSetSm && <source media="(min-width: 640px)" srcSet={srcSetSm} />}
      {srcSetXs && <source srcSet={srcSetXs} />}
      {url && <img src={url} className={props.className} width={width} height={height} alt={alt} />}
    </picture>
  );
}

Image.propTypes = {
  source: PropTypes.shape({
    url: PropTypes.string,
    thumbnails: PropTypes.object,
  }),
  width: PropTypes.number,
  alt: PropTypes.string,
  className: PropTypes.string,
};

export default Image;

import React from "react";
import { Link } from "gatsby";
import * as styles from "./breadcrumb.module.scss";

function Breadcrumb({ items }) {
  return items.length == 0 ? (
    ""
  ) : (
    <ul className={styles.breadcrumb}>
      {items.map((item) => {
        const maxCharLength = 25;
        const words = item.name.split(" ");
        // truncatedName should contain at least 1 word.
        let truncatedName = words[0];

        for (let i = 1; i <= words.length; i++) {
          const word = words[i];
          if (!word) {
            break;
          }
          if (truncatedName.length + word.length >= maxCharLength) {
            truncatedName += " …";
            break;
          }
          truncatedName += ` ${word}`;
        }
        return (
          <li key={item.name}>
            {item.link && <Link to={item.link}>{truncatedName}</Link>}
            {!item.link && <span>{truncatedName}</span>}
          </li>
        );
      })}
    </ul>
  );
}

export default Breadcrumb;

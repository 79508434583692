import { graphql, Link, useStaticQuery } from "gatsby";
import React, { useEffect, useState } from "react";
import Image from "../image/image";
import Menu from "../menu/menu";
import * as styles from "./header.module.scss";

function Header({ path }) {
  let previousScrollY = 0;
  let firstScrollDownY = undefined;
  let firstScrollUpY = undefined;
  const threshold = 80;
  const [isHeaderHidden, setHeaderHidden] = useState(false);
  const { prismicMetadata } = useStaticQuery(graphql`
    {
      prismicMetadata {
        data {
          site_name
          logo {
            thumbnails {
              lg_3x {
                url
              }
              lg_2x {
                url
              }
              lg {
                url
              }
              xs_3x {
                url
              }
              xs_2x {
                url
              }
              xs {
                url
              }
            }
            dimensions {
              width
              height
            }
          }
        }
      }
    }
  `);
  const siteName = prismicMetadata.data.site_name;
  const logo = prismicMetadata.data.logo;

  const handleScroll = () => {
    if (isScrollingDown()) {
      if (isScrolledDownPastThreshold()) {
        setHeaderHidden(true);
      }
    } else {
      if (isScrolledUpPastThreshold() || isAtTop()) {
        setHeaderHidden(false);
      }
    }
  };

  const getCurrentScrollY = () => window.pageYOffset;
  const isAtTop = () => getCurrentScrollY() === 0;
  const isScrollingDown = () => {
    let isScrollingDown = getCurrentScrollY() > previousScrollY;
    isScrollingDown ? (firstScrollUpY = undefined) : (firstScrollDownY = undefined);
    previousScrollY = getCurrentScrollY();
    return isScrollingDown;
  };
  const isScrolledDownPastThreshold = () => {
    if (firstScrollDownY == undefined) {
      firstScrollDownY = getCurrentScrollY();
      return false;
    } else {
      return getCurrentScrollY() - firstScrollDownY >= threshold;
    }
  };
  const isScrolledUpPastThreshold = () => {
    if (firstScrollUpY == undefined) {
      firstScrollUpY = getCurrentScrollY();
      return false;
    } else {
      return firstScrollUpY - getCurrentScrollY() >= threshold;
    }
  };

  useEffect(() => {
    setHeaderHidden(false);

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [path]);

  return (
    <header>
      <div className={`${styles.header} ${isHeaderHidden ? styles.headerHidden : ""}`}>
        <svg
          className={styles.headerBackground}
          viewBox="0 0 1600 128"
          xmlns="http://www.w3.org/2000/svg"
          preserveAspectRatio="none"
        >
          <path d="M1600 0V80H1011C709 80 805.5 128 576.5 128H0V0H1600Z" />
        </svg>

        <div className={styles.headerInner}>
          <Link to="/" className={styles.brand}>
            <Image source={logo} alt={siteName} />
          </Link>
          <Menu collapse={isHeaderHidden} path={path} />
        </div>
      </div>
    </header>
  );
}

export default Header;

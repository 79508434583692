import { Link } from "gatsby";
import React, { useEffect, useState } from "react";
import * as styles from "./menu.module.scss";

function Menu({ collapse, path }) {
  const [isMobileMenuExpanded, setMobileMenuExpansion] = useState(false);

  const collapseMenu = () => {
    setMobileMenuExpansion(false);
  };

  useEffect(collapseMenu, [collapse, path]);

  return (
    <>
      <button className={styles.navBtn} onClick={() => setMobileMenuExpansion(!isMobileMenuExpanded)}>
        <svg viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
          <title>Menu</title>
          <path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z" />
        </svg>
      </button>

      <nav className={`${styles.nav} ${isMobileMenuExpanded ? styles.navExpanded : ""} `}>
        {[
          {
            route: "/",
            title: "Welkom",
          },
          {
            route: "/sonja/",
            title: "Sonja",
          },
          {
            route: "/werking-doel/",
            title: "Werking/Doel",
          },
          {
            route: "/aanbod/",
            title: "Aanbod",
          },
          {
            route: "/contact/",
            title: "Contact",
          },
        ].map((link) => (
          <Link
            className={styles.navItem}
            activeClassName={styles.navItemActive}
            partiallyActive={link.route == "/" ? false : true}
            key={link.title}
            to={link.route}
          >
            <span className={styles.navItemInner}>{link.title}</span>
          </Link>
        ))}
      </nav>
    </>
  );
}

export default Menu;
